<template>
  <div class="page-auditschedule">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>

    <noticeAdd v-if="tabNum === 0" @choose='chooseItem'/>
    <noticeSendList v-if="tabNum === 1" />
  </div>
</template>
  
  <script>
import { Icon, Image, ImagePreview } from "vant";
import NoticeAdd from "./NoticeAdd/index.vue";
import NoticeSendList from "./NoticeSendList/index.vue";
export default {
  components: {
    NoticeSendList,
    NoticeAdd,
  },
  data() {
    return {
      tabs: ["新增公告", "发布记录"],
      tabNum: 0,
      
    };
  },
  mounted() {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active);
    }
  },
  methods: {
    tabClick(index) {
      this.tabNum = index;
    },
    chooseItem(){
      this.tabNum = 1;
    }
  },
};
</script>
  <style lang="less" scoped>
@import "./index.less";
</style>
  