<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            required
            type="text"
            v-model="viewData.Title"
            name="Title"
            label="标题"
            placeholder="请输入标题"
            :rules="[{ required: true, message: '请输入标题' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.ProclamationTypeName"
            name="ProclamationTypeName"
            label="公告类型"
            placeholder="请选择公告类型"
            :rules="[{ required: true, message: '请选择公告类型型' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="EnumName"
              :columns="proclamationTypeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.PeopleIdList"
            name="PeopleIdList"
            label="接收对象"
            placeholder="接收对象"
            :rules="[{ required: true, message: '请选择接收对象' }]"
            @click="showPicker1 = true"
          />
          <van-popup v-model="showPicker1" position="bottom">
            <div style="height: 300px; overflow: scroll; position: relative">
              <div v-if="peopleList.length > 0">
                <van-checkbox-group
                  v-model="peopleIds"
                  @change="selectFn"
                  style="padding: 10px 20px 0 20px"
                >
                  <van-checkbox
                    v-for="(item, index) in peopleList"
                    :key="index"
                    :name="item.ID"
                    style="margin-bottom: 10px; font-size: 16px"
                    >{{
                      item.ClassName ? item.ClassName : item.RealName
                    }}</van-checkbox
                  >
                </van-checkbox-group>
              </div>
              <div
                v-else
                style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                <van-empty description="暂无数据" />
              </div>
            </div>
          </van-popup>

          <van-field
            required
            v-model="viewData.Content"
            name="公告内容"
            rows="3"
            label="公告内容"
            type="textarea"
            placeholder="请输入公告内容"
            :rules="[{ required: true, message: '请输入公告内容' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.AuditUserIDListName"
            name="weituo"
            label="审核人"
            placeholder="审核人"
            :rules="[{ required: true, message: '请选择审核人' }]"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="auditUserName"
              :columns="pepoleList1"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>

          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                v-model="viewData.AccessoryPostList"
                :deletable="true"
                :preview-full-image="false"
                accept="*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import { json } from "body-parser";
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Icon,
  Toast,
  Checkbox,
  CheckboxGroup,
  Empty,
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Empty.name]: Empty,
  },
  computed: {
    minDate: function () {
      let date = this.defaultTime;
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + 1
      );
    },
  },
  data() {
    return {
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      SchoolID: window.localStorage.getItem("SchoolID"),
      id: null,
      viewData: {
        AccessoryPostList: [],
        CopyUserIDListName: "",
      },
      proclamationTypeList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      showPicker1: false,
      loading: false,
      pepoleList1: [],
      pepoleList2: [],
      defaultTime: new Date(),
      defaultTime2: new Date(),
      minStartTime: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ),
      peopleList: [],
      peopleIds: [],
      selectRoomList: [],
    };
  },
  mounted() {
    this.getEnum();
    this.getAuditUserList();

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .get("/api/Proclamation/Get", {
          ID: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getClassList(res.data.ProclamationType);

            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList,

              ProclamationTypeName: res.data.ProclamationTypeName,
              PeopleIdList:
                res.data.ProclamationType === 1
                  ? res.data.UserNameArray
                  : res.data.ClassNameArray,
            };
            this.viewData.FlowID = res.data.FlowID;
            this.viewData.AuditUserIDListName = res.data.FlowName;
            if (res.data.ProclamationType === 1) {
              this.peopleIds = res.data.UserIDList;
            } else {
              this.peopleIds = res.data.ClassIDList;
            }
          }
        });
    },
    getClassList(type) {
      this.$axios
        .get("/api/User/AllSchoolList", {
          schoolID: this.SchoolID,
        })
        .then((res) => {
          if (res.code === 200) {
            if (type === 1) {
              this.peopleList = res.data.returnList[0].Module;
              this.selectRoomList = res.data.returnList[0].Module;
            } else {
              this.peopleList = res.data.returnList[1].Module;
              this.selectRoomList = res.data.returnList[1].Module;
            }
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    getEnum() {
      this.$axios.get("/api/Base/GetEnum?type=ProclamationType").then((res) => {
        if (res.code === 200) {
          this.proclamationTypeList = res.data.List;
        }
      });
    },
    getAuditUserList() {
      this.$axios.get("/api/AuditFlow/List?auditType=3").then((res) => {
        if (res.code === 200) {
          this.pepoleList1 = res.data;
        }
      });
    },
    onConfirm(value) {
      this.viewData.ProclamationTypeName = value.EnumName;
      this.viewData.ProclamationType = value.EnumValue;

      this.showPicker = false;
      // 获取接收对象列表
      this.getClassList(value.EnumValue);
    },
    onConfirm1(value) {
      this.viewData.AcceptUserName = value.realName;
      this.viewData.AcceptUserID = value.UserID;
      this.showPicker1 = false;
    },
    onConfirm2(value) {
      this.viewData.FlowID = value.ID;
      this.viewData.AuditUserIDListName = value.auditUserName;
      this.showPicker2 = false;
    },

    selectFn(v) {
      let arr = [];
      var roomList = this.selectRoomList;
      v.forEach((element) => {
        roomList.some(function (a) {
          if (a.ID == element) {
            var obj = roomList.find((item) => item.ID == element);
            arr.push(obj.ClassName ? obj.ClassName : obj.RealName);
          }
        });
      });

      this.viewData.PeopleIdList = arr.join();
    },
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0,
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
        Toast.clear();
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url,
          });
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      debugger;
      let that = this;
      let arr = [];
      console.log(this.peopleIds);
      delete this.viewData.ProclamationTypeName;
      delete this.viewData.PeopleIdList;
      delete this.viewData.AuditUserIDListName;
      arr = this.peopleIds;

      let info = {
        Title: null,
        Content: null,
        ProclamationType: null,
        UserIDList: null,
        ClassIDList: null,
        FlowID: null,
        AccessoryPostList: null,
      };
      info.Title = this.viewData.Title;
      info.Content = this.viewData.Content;
      info.ProclamationType = this.viewData.ProclamationType;
      info.UserIDList = this.viewData.ProclamationType === 1 ? arr : [];
      info.ClassIDList = this.viewData.ProclamationType === 2 ? arr : [];
      info.FlowID = this.viewData.FlowID;
      info.AccessoryPostList = this.viewData.AccessoryPostList;
      let api;
      if (this.id) {
        (api = "/api/Proclamation/Edit"), (info.ID = this.id);
      } else {
        api = "/api/Proclamation/Add";
      }

      this.$axios.post(api, info).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          // setTimeout(() => {
          //   // that.$router.replace({
          //   //   path: "NoticeMain",
          //   //   query: {
          //   //     active: 1,
          //   //   },
          //   // });
          // }, 1000);
          this.$emit("choose");
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    clearPeople2() {
      // this.viewData.CopyUserIDListName = ''
      this.$set(this.viewData, "CopyUserIDListName", "");
      this.$set(this.viewData, "CopyUserIDList", []);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
